<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4 mb-3">
        <form class="row" @submit.prevent="postAssignment">
            <div class="form-group col-lg-6">
                <label for="">Question Type</label>
                <select @change="onChangeType" class="form-control" v-model="type" required>
                    <option value="1">With Video</option>
                    <option value="2">With Audio</option>
                    <option value="3">Only Text</option>
                </select>
            </div>
            <div class="form-group col-lg-6" v-if="type == 1">
                <label for="">Video File</label><br>
                <file-upload
                class="btn btn-lg btn-current mr-1"
                @input="updateFile"
                @input-file="inputFile"
                @input-filter="fileFilter"
                :custom-action="fileAction"
                accept="video/mp4"
                ref="upload" required>
                <i class="fa fa-plus"></i>
                Upload Video
                </file-upload>

                <a v-if="path" :href=" path" target="_blank" class="btn btn-lg btn-success">Current File</a>

                <button type="button" class="btn btn-lg btn-danger ml-1"  v-if="!$refs.upload || $refs.upload.active" @click.prevent="$refs.upload.active = false">
                <i class="fa fa-stop" aria-hidden="true"></i>
                Stop Upload
                </button>
            </div>
            <div class="form-group col-lg-6" v-if="type == 2">
                <label for="">Audio File</label><br>
                <file-upload
                class="btn btn-lg btn-current mr-1"
                @input="updateFile"
                @input-file="inputFile"
                @input-filter="fileFilter"
                :custom-action="fileAction"
                accept="audio/mpeg"
                ref="upload" required>
                <i class="fa fa-plus"></i>
                Upload Audio
                </file-upload>

                <a v-if="path" :href=" path" target="_blank" class="btn btn-lg btn-success">Current File</a>

                <button type="button" class="btn btn-lg btn-danger ml-1"  v-if="!$refs.upload || $refs.upload.active" @click.prevent="$refs.upload.active = false">
                <i class="fa fa-stop" aria-hidden="true"></i>
                Stop Upload
                </button>
            </div>
            <div class="col-12 form-group mb-3">
                <label for="">Rubrics</label>
                <select required v-model="rubric_id" class="form-control text-grey-900 font-xsss fw-600">
                    <option value="" disabled selected>-- Select Rubrics --</option>
                    <option v-for="(item, index) in rubrics" :key="index" :value="item.id">{{item.name}}</option>
                </select>
            </div>
            <div class="form-group col-lg-12">
                <label for="">Skills</label>
                <select class="form-control" v-model="skills" required>
                    <option value="1">Listening</option>
                    <option value="3">Speaking</option>
                </select>
            </div>
            <div class="form-group col-lg-12">
                <label for="">Question</label><br>
                <vue-editor @text-change="onChange()" v-model="content"/>
            </div>
            <div class="form-group col-lg-12">
                <label for="">Submission Type</label>
                <select @change="onSubmissionTypeChange()" class="form-control" v-model="submission_type" required>
                    <option value="1">File Upload</option>
                    <option value="2">Essay</option>
                    <option value="4">True &amp; False</option>
                    <option value="5">Fill In The Blank</option>
                    <option value="6">Matching</option>
                    <option value="7">Text No Question</option>
                </select>
            </div>
            <div v-if="submission_type == 4" class="form-group col-12">
                <label for="">Answer</label><br>
                <a href="javascript:void(0)" @click="setAnswer(1)" :class="{'btn-success' : answer == 1, 'btn-outline-success' : answer != 1}" class="btn btn-lg mr-2">True</a>
                <a href="javascript:void(0)" @click="setAnswer(0)" :class="{'btn-danger' : answer == 0, 'btn-outline-danger' : answer != 0}" class="btn btn-lg">False</a>
            </div>
            <div v-if="submission_type == 5" class="form-group col-12">
                <a @click="mergeFill()" href="javascript:void(0)" class="btn btn-current">Merge <b>[BLANK]</b></a>
            </div>
            <div v-if="submission_type == 5 && ((content.split('[BLANK]').length - 1) > 0)" class="col-12">
                <label for="">Answer</label>
                <div v-for="n in (content.split('[BLANK]').length - 1)" :key="n" class="form-group">
                    <input @keyup="onChange()" v-model="arrAnswer[n-1]" type="text" class="form-control" :placeholder="'Input answer for [BLANK] ' + n" required>
                </div>
            </div>
            <div v-if="submission_type == 6" class="form-group col-12">
                <div class="card border-0">
                    <div class="input-group mb-2" v-for="j in count" :key="j">
                        <input type="text" @keyup="onChange()" v-model="option[j-1]" class="form-control mr-2" placeholder="Enter your Promps" required>
                        <input type="text" @keyup="onChange()" v-model="arrAnswer[j-1]" class="form-control" placeholder="Enter your Answer" required>
                    </div>
                </div>
                <div class="w-100 text-right">
                    <button v-if="(count > 1)" type="button" @click="() => {this.count--;onChange()}" class="btn btn-danger btn-sm mr-1"><i class="fas fa-minus"></i></button>
                    <button type="button" @click="() => {this.count++;onChange()}" class="btn btn-success btn-sm ml-1"><i class="fas fa-plus"></i></button>
                </div>
            </div>
            <div class="form-group col-lg-12 text-right">
                <button type="submit" class="btn btn-current mt-3">Submit</button>
            </div>
        </form>
    </div>
</template>
<script>
import axios from 'axios'
import FileUpload from 'vue-upload-component'

export default {
    components:{
        FileUpload
    },
    created(){
        this.getRubrics();
    },
    data(){
        return {
            slug: this.$route.params.idCourse,
            id: this.$route.params.idAssignment,
            content:'',
            media: process.env.VUE_APP_URL_CLOUD,
            type: 1,
            rubrics: [],
            files: [],
            submission_type: 1,
            path:'',
            rubric_id:'',
            skills:1,
            answer: '1',
            option:[],
            arrAnswer: [],
            count: 1
        }
    },
    methods:{
        onChangeType(){
            if(this.files[0] || this.path) {
                // this.deleteFile(this.files[0])
            }
        },
        setAnswer(answer){
            this.answer =  answer
        },
        mergeFill(){
            this.content += '<b>[BLANK]</b>'
        },
        onChange(){
            if(this.submission_type == 5) {
                let length = this.content.split('[BLANK]').length;
                if(length) {
                    let arr = []
                    this.arrAnswer.map((item, index) => {
                        if(index < length-1) {
                            arr.push(item)
                        }
                    })
                    this.arrAnswer = arr
                    this.answer = this.arrAnswer.join("*##*");
                } else {
                    this.arrAnswer = []
                    this.answer = null
                }
            } else if(this.submission_type == 6) {
                if(this.count) {
                    let arr = [];
                    let arrOpt = [];
                    this.arrAnswer.map((item, index) => {
                        if(index < this.count) {
                            arr.push(item)
                        }
                    })
                    this.option.map((item, index) => {
                        if(index < this.count) {
                            arrOpt.push(item)
                        }
                    })
                    this.arrAnswer = arr
                    this.option = arrOpt
                    this.answer = this.arrAnswer.join("*##*");
                } else {
                    this.arrAnswer = []
                    this.answer = null
                }
            }
        },
        onSubmissionTypeChange() {
            if(this.submission_type == 4) {
                this.answer = '1';
            } else {
                this.answer = ''
            }
            this.count = 1;
            this.option = []
            this.arrAnswer = []
        },
        async getRubrics() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/rubrics?slug=` + this.slug, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.rubrics = res.data.data
            })
        },
        async postAssignment(){
            var data = {
                slug: this.slug,
                id: this.id,
                quest_type: this.type,
                path: this.path,
                rubric_id: this.rubric_id,
                skills: this.skills,
                question: this.content,
                submission_type: this.submission_type,
                answer: this.answer,
                option: this.option,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/assignment/quest`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Question - Individual !',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$emit('new-data')
                this.path=''
                this.content=''
                this.rubric_id=''
                this.files= []
                this.submission_type = 1
                this.answer= ''
                this.option=[]
                
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Question - Individual !',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        updateFile(value) {
            this.files = value
        },
        async fileAction(file) {
            return this.postFile(file)
        },
        async inputFile(newFile, oldFile) {
            if (!newFile && oldFile) {
                if (oldFile.success && oldFile.response.path) {
                    // this.deleteFile(oldFile)
                }
            }
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs.upload.active) {
                    this.$refs.upload.active = true
                }
            }
        },
        fileFilter(newFile, oldFile, prevent) {
            if (this.type == 1) {
                if (newFile && !oldFile) {
                    if (!/\.(mp4)$/i.test(newFile.name)) {
                        return prevent()
                    }
                }
            } else {
                if (newFile && !oldFile) {
                    if (!/\.(mp3)$/i.test(newFile.name)) {
                        return prevent()
                    }
                }
            }
        },
        async postFile(file){
            let data = new FormData();
            data.append('dir', this.type == 1 ? 'video' : 'audio');
            data.append('attachment', file.file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                file.response = res.data
                this.path = res.data.path
                return res.data
            })
        },
        async deleteFile(file){
            let data = new FormData();
            data.append('path', file.response.path);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/delete`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.files = []
                this.path = ''
                return res.data
            })
        },
    }
};
</script>